@import "../../../styles/variables.scss";

#button-secondary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-weight: 700;
  color: $light-blue;
  font-size: 14px;
  line-height: 17px;
  border-radius: 11px;
  padding-left: 35px;
  padding-right: 35px;
  border: none;
  width: 100%;

  &.dark-blue {
    color: $primary-color;
  }

  &.white {
    color: $white;
  }

  &:disabled {
    opacity: 0.6;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1024px) {
    height: 47px;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1280px) {
    height: 56px;
    font-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
