@import "../../../styles/variables.scss";
@import "../../../styles/modal";
@import "../../../styles/form";

.msisdn-modal {
  @extend .modal;
  .ant-modal-content {
    .fener-logo {
      width: 96px;
    }

    #msisdn-form {
      @extend .form;
      width: 100%;
      .ant-form-item {
        .ant-form-item-control {
          .phone-input {
            .phone {
              width: 100%;
              background-color: $input-bg;
              border-radius: 4px;

              &.error-border {
                border: 2px solid $gold !important;
              }
            }

            .flag-dropdown {
              border-radius: 4px 0 0 4px;
              background: none;
              border: none;

              .selected-flag {
                border-radius: 4px 0 0 4px;
                background: none;
              }
            }
          }
          .ant-input-affix-wrapper {
            .ant-input-suffix {
              .ant-form-item-feedback-icon-warning {
                color: $gold;
              }
            }
          }
          .ant-form-item-explain {
            .ant-form-item-explain-warning {
              color: $gold;
              margin: 6px 0;
              font-size: 11px;
            }
          }
        }
        .ant-form-item-explain {
          .ant-form-item-explain-error {
            font-size: 11px;
            line-height: 13px;
            color: $gold;
            margin-top: 5px;
          }
        }
      }
    }
    .contract-link {
      color: black;
      font-weight: 700;
    }
    .link {
      color: $gray;
      font-size: 14px;
      text-align: center;
    }
  }
}
