@import "../../../styles/modal";

.prize-ready-modal {
  @extend .modal;

  .copy-wrapper {
    cursor: pointer;
    width: 100%;

    .clipboard-content {
      border: 1px dashed $primary-color;
      background-color: $input-bg;
      border-radius: 6px;
      padding: 20px;
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;

      .clipboard-text {
        color: $primary-color;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .copy {
      margin-right: 2px;
      width: 15px;
      height: 15px;
    }
  }
}

.copy-message {
  .ant-message-notice-content {
    background: $input-bg;
    border-radius: 4px;
    color: $primary-color;
    font-size: 14px;
    font-weight: 600;
  }
}
