@import "variables";

.form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $input-bg inset !important;
    -webkit-text-fill-color: $primary-color;
  }

  &.app-form {
    $border-radius: 4px;
    $height: 44px;

    .ant-form-item-label {
      label {
        display: block;
        color: $gray;
      }
    }

    .ant-input {
      height: $height;
      border-radius: $border-radius;
      border: 2px solid transparent;
      box-shadow: none;
      background-color: $input-bg;
      font-size: 16px;
      line-height: 19px;
      color: $primary-color;
      transition: none;
    }

    .ant-picker {
      @extend .ant-input;
      width: 100%;
    }

    .ant-select {
      display: flex;
      align-items: center;

      .ant-select-selector {
        @extend .ant-input;

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-checkbox-wrapper {
      color: $gray;
      line-height: 24px;

      .ant-checkbox-inner {
        border: 2px solid $primary-color;
      }

      &.ant-checkbox-wrapper-checked {
        .ant-checkbox-inner {
          background-color: transparent;

          &::after {
            border-color: $primary-color;
          }
        }
      }
    }

    .ant-form-item-has-error {
      .ant-form-item-label {
        label {
          color: $gold;
        }
      }

      .ant-input,
      .ant-picker,
      .ant-select-status-error .ant-select-selector,
      .ant-checkbox-inner {
        border-color: $gold !important;
      }
    }
  }
}
